@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: transparent;
  --secondary: #414141;
}

@font-face {
  font-family: 'Montserrat-Thin';
  src: url('../src//assets//fonts/Montserrat-Thin.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat-Light';
  src: url('../src//assets//fonts/Montserrat-Light.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat-Regular';
  src: url('../src//assets//fonts/Montserrat-Regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat-Italic';
  src: url('../src//assets//fonts/Montserrat-Italic.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat-Medium';
  src: url('../src//assets//fonts/Montserrat-Medium.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat-MediumItalic';
  src: url('../src//assets//fonts/Montserrat-MediumItalic.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('../src//assets//fonts/Montserrat-SemiBold.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat-Bold';
  src: url('../src//assets//fonts/Montserrat-Bold.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat-BoldItalic';
  src: url('../src//assets//fonts/Montserrat-BoldItalic.ttf');
  font-display: swap;
}

html,
body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}

div[role='menu'] {
  visibility: hidden;
  opacity: 0;
}

div[role='menu'].visible {
  visibility: visible;
  opacity: 1;
}

.wrapper {
  max-width: 1920px;
  width: 100%;
  padding-left: 35px;
  padding-right: 35px;
}

.wrapper-no-padding {
  max-width: 1920px;
  width: 100%;
}

.wrapper-mobile {
  max-width: 1920px;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

#sub-menu-prelaunch:before {
  height: 24px;
  position: absolute;
  display: block;
  width: 24px;
  content: " ";
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.22855 5.27145C2.03329 5.07618 1.71671 5.07618 1.52145 5.27145C1.32618 5.46671 1.32618 5.78329 1.52145 5.97855L8.64645 13.1036C8.84171 13.2988 9.15829 13.2988 9.35355 13.1036L16.4786 5.97855C16.6738 5.78329 16.6738 5.46671 16.4786 5.27145C16.2833 5.07618 15.9667 5.07618 15.7714 5.27145L9 12.0429L2.22855 5.27145Z' fill='white'/%3E%3C/svg%3E%0A");
  right: 12px;
  margin-top: 5px;
  z-index: -1;
}

#sub-menu-prelaunch-header:before {
  height: 24px;
  position: absolute;
  display: block;
  width: 24px;
  content: " ";
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.22855 5.27145C2.03329 5.07618 1.71671 5.07618 1.52145 5.27145C1.32618 5.46671 1.32618 5.78329 1.52145 5.97855L8.64645 13.1036C8.84171 13.2988 9.15829 13.2988 9.35355 13.1036L16.4786 5.97855C16.6738 5.78329 16.6738 5.46671 16.4786 5.27145C16.2833 5.07618 15.9667 5.07618 15.7714 5.27145L9 12.0429L2.22855 5.27145Z' fill='white'/%3E%3C/svg%3E%0A");
  right: 0;
  margin-top: 5px;
}
.profile-content-view {
  min-height: calc(100vh - 219px);
}

.rws-backdrop  {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center !important;
  z-index: 1400;
}
.read-more-text-mask {
  -webkit-mask-image: linear-gradient(to bottom, black 20%, transparent 90%);
  mask-image: linear-gradient(to bottom, black 20%, transparent 90%);
}
.read-more-text-container p:not(:first-child),
.read-more-text-container h3{
    margin-top: 16px;
}
.read-more-text-container.display-preview {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
.read-more-text-container.display-preview-mobile {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.read-more-text-container.display-preview *,
.read-more-text-container.display-preview-mobile * {
  display: inline;
}
.read-more-text-container.display-preview p:not(:first-child),
.read-more-text-container.display-preview h3,
.read-more-text-container.display-preview-mobile p:not(:first-child),
.read-more-text-container.display-preview-mobile h3{
    margin-top: 0 !important;
    margin-left: 4px;
}
.read-more-text-container strong {
    font-weight: bold;
    font-family: 'Montserrat-Bold';
}
.read-more-text-container a {
  text-decoration: underline;
}
.desctop-explore {
}

.custom-img {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}

.img-drop-shadow {
  filter: drop-shadow(0px 0px 1.5px rgba(255, 255, 255, 1));
}

.unset-img {
  width: 82%;
  margin: auto;
}
.unset-img > span {
  position: unset !important;
}

.unset-img-600px {
  width: 600px;
  margin: auto;
}
.unset-img-600px > span {
  position: unset !important;
}

.custom-gradient {
  background: rgb(234,6,254);
  background: linear-gradient(10deg, rgba(234,6,254,1) 0%, rgba(164,63,255,1) 44%, rgba(54,189,253,1) 100%);
}

.custom-gradient2 {
  background: rgb(234,6,254);
  background: linear-gradient(10deg, rgba(234,6,254,1) 0%, rgba(164, 63, 255, 1) 43.39%, rgba(54,189,253,1) 100%);
}

.css-view-175oi2r,
.css-175oi2r {
  z-index: unset;
}

.w-screen-custom {
  width: calc(100vw - 16px);
}

.marker {
  width: 39px;
  height: 48px;
}
.marker-container {
  width: 39px;
  height: 48px;
}
.marker-icon {
  width: 39px;
  height: 48px;
  position: relative;
  z-index: 10;
}
.marker-avatar {
  width: 30px !important;
  height: 30px;
  position: absolute;
  border-radius: 100px;
  top: 6px;
  left: 4px;
  z-index: 5;
}