.react-calendar { 
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  color: #fff;
  font-family: 'Montserrat-Regular';
  margin-bottom: 20px;
  border-bottom: 1px solid #343434;
}
.react-calendar.small-calendar {
  margin-bottom: 0;
  border-bottom: 0;
}

.myClassName abbr {
  border: 1px solid white;
  border-radius: 50px;
}

.react-calendar__month-view__weekdays {
  border-bottom: 1px solid #343434;
  text-align: center;
  padding: 20px 0;
  font-size: 16px;
  font-family: 'Montserrat-Bold', sans-serif;
  font-weight: bold;
  margin: 0 -40px 14px;
}
.react-calendar.small-calendar .react-calendar__month-view__weekdays {
  margin: 0 0 14px;
}
.react-calendar.mobile-responsive .react-calendar__month-view__weekdays {
  font-size: 10px;
  padding: 10px 0;
  margin: 0 0 14px;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation {
  border-top: 1px solid #343434;
  border-bottom: 1px solid #343434;
  padding: 12px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.react-calendar.mobile-responsive .react-calendar__navigation {
  padding: 7px 16px;
}

.react-calendar__navigation__prev2-button{
  visibility: hidden;
}

.react-calendar__navigation__next2-button{
  visibility: hidden;
}
.react-calendar__navigation__label{
  flex: 1;
  min-width: 500px;
}
.react-calendar.small-calendar .react-calendar__navigation__label {
  min-width: 0;
}
.react-calendar.mobile-responsive .react-calendar__navigation__label {
  min-width: 0;
  width: 100%;
}
.react-calendar__navigation button .react-calendar__navigation__arrow{
  width: 480px;
  background-color: red;
}

.react-calendar__navigation button {
  color: #fff;
  background: none;
  font-size: 16px;
  font-family: 'Montserrat-Bold', sans-serif;
  font-weight: bold;
}
.react-calendar.mobile-responsive .react-calendar__navigation button {
  font-size: 10px;
}

/* hover color of year month */
.react-calendar__navigation button:enabled:hover {
  background-color: transparent;
}
.react-calendar__navigation button[disabled] {
  background-color: transparent;
}
abbr[title] {
  text-decoration: none;
}
.react-calendar__month-view__days {
  margin: 0 -40px;
}
.react-calendar.small-calendar .react-calendar__month-view__days {
  margin: 0;
  display: grid !important;
  grid-template-columns: repeat(7, 1fr);
}
.react-calendar.mobile-responsive .react-calendar__month-view__days {
  margin: 0;
  display: grid !important;
  grid-template-columns: repeat(7, 1fr);
}
.react-calendar__month-view__days__day {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
/* hover color for date */
.react-calendar__tile {
  height: 65px;
  padding: 0;
  margin: 0;
  margin-bottom: 14px;
}
.react-calendar.small-calendar .react-calendar__tile {
  height: 40px;
}
.react-calendar.mobile-responsive .react-calendar__tile {
  height: 30px;
}
.react-calendar__tile abbr {
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.react-calendar.small-calendar .react-calendar__tile abbr {
  width: 40px;
  height: 40px;
  border: 0;
  border-radius: 0;
}
.react-calendar.mobile-responsive .react-calendar__tile abbr {
  width: 30px;
  height: 30px;
}
.react-calendar__tile:enabled:hover abbr {
  background: #f8f8fa;
  border-radius: 50px;
  color: #EA06FE;
}
.react-calendar__tile--now abbr {
  background: transparent;
  border: 1px solid #44B3F8 !important;
  border-radius: 50px !important;
  font-weight: bold;
  color: #fff;
}
.react-calendar__tile--now:enabled:hover abbr {
  background: transparent;
  border-radius: 50px;
  font-weight: bold;
  color: #fff;
}
.react-calendar__tile--hasActive:enabled:hover abbr {
  background: #f8f8fa;
}
.react-calendar__tile--active abbr {
  background: #EA06FE;
  border-radius: 6px;
  font-weight: bold;
  color: white;
}
.react-calendar__tile--active:enabled:hover abbr {
  background: #EA06FE;
  color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover abbr {
  background-color: #282828;
}
.react-calendar__tile--range abbr {
  background: #282828;
  color: #fff;
  border-radius: 0;
  border: 0
}
.react-calendar__tile--rangeStart abbr {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  background: #EA06FE;
  border: 0px !important;
  color: white;
}

.react-calendar__tile--rangeEnd abbr {
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
  background: #EA06FE;
  border: 0px !important;
  color: white;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #939393;
}